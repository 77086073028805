@import '../../theme/colors';

.layoutContainer {

  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: 100vh;
  margin-top: 40px;

  .topMenu {
    background-color: $secondary;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: $secondary_lighter solid 1px;

    padding-right: 24px;

    .navGroup {
      display: flex;
      flex-direction: row;
      align-items: center;

      .brand {
        margin-left: 10px;
        margin-right: 4px;
        padding: 6px;
        height: 50px
      }

      .testBadge {
        background: red;
        color: white;
        border-radius: 4px;

        margin-left: 21px;
        margin-right: 4px;
        padding: 6px 14px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .navButton {
        height: 60px;
        font-size: 0.9rem;
        padding: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 12px;

        &.active {
          background-color: $secondary;
        }
      }
    }
  }

  .layout {
    display: flex;
    flex-direction: row;
    min-width: 98vw;
    min-height: calc(100vh - 36px);
    overflow-y: hidden;

    .sideBar {
      z-index: 2;
      position: relative;

      // background-image: linear-gradient(180deg, #34315F, #232142);
      background-color: #ffffff;
      // color:black;
      padding: 0;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-height: 100vh;
      border-right: 1px $black20;

      .selector {
        padding-top: 20px;
        position: relative;
      }

      .trialPeriod {
        background-color: #232142;
        // background-color: white;

        position: absolute;
        bottom: 0;
        left: 0;

        z-index: 100;
        height: 90px;
        width: 200px;
        padding-top: 12px;

        .trialBar {
          width: 100%;
          background: $transparent;

          height: 6px;

          .activeBar {
            height: 6px;
            background: $accent_green;
          }
        }

        p {
          margin: 12px 0;
          color: $accent_green;
        }
      }
    }

    .mainContent {
      position: relative;
      overflow-y: auto;
      width: 100%;
      background-color: $black5;

      .contentContainer {
        z-index: 2;
        position: absolute;
        width: 100%;
        min-width: 700px;

      }

      &.padding {
        padding: 64px 42px;
      }
    }
  }
}