@import '../../theme/colors';


.domainSelector {
  position: relative;
  // margin-left: 24px;

  .secondaryColor {
    color: $secondary
  }

  .BackDrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    //background-color: rgba(0, 0, 0, .6);
    
    cursor: pointer;
  }

  .domainWrapper {
    min-width: 250px;
    padding: 6px 10px;
    margin-top:30px;

    background-color: white;
    border-radius: 20px;

    &.open {

      box-shadow: 0 2px 2px 0 rgba(69, 68, 104, 0.14), 0 3px 1px -2px rgba(69, 68, 104, 0.14), 0 1px 5px 0 rgba(69, 68, 104, 0.14);

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .maxWidth {
      margin-left: 4px;
      overflow: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .creditor {
      margin-top: 10px;
      margin-left: -2px;
      margin-bottom: -1px;
      padding-bottom: 4px;
      border-bottom: 1px solid $gray_light;
    }

    .domain {
      overflow: hidden;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .input {
      margin: 2px 0 2px 4px;
      color: $secondary;

      font-size: 0.85rem;
      font-weight: 700;
      line-height: 1.05rem;
    }

    .down {
      margin-left: 4px;
      transition: transform 0.8s;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    .all_domains {
      position: absolute;
      padding: 2px 15px 12px 11px;
      min-width: 250px;
      margin-left: -10px;
      // margin-right:30px;
      background: white;

      box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      &.closed {
        max-height: 0 !important;
        opacity: 0;
        padding-top: 0;
      }

      &.open {
        opacity: 1;
        overflow-y: auto;
        z-index: 800;
        width: 100%;
        max-height: 310px;
      }

      .row {
        overflow: hidden;
        padding-top: 6px;

        &.closed {
          display: None;
        }
      }

      .highlight {
        color: $gray
      }
    }
  }

}