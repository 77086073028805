.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: white;
}

.content {
    flex: 1;
    padding: 157px 70px 60px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(180deg, #34315F, #232142);
}

.content_text {
    text-align: center;
    margin: auto;
}

.footer {
    height: 170px;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #131330;
}

