@import "../../theme/colors";

.tableCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableRow {
  margin: 6px 6px 0 6px;
  width: 100%;

  .wrapper {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.line {
      background-color: $black2;
      transition: box-shadow 0.6s ease-in;
      box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

    }

    &.active {
      box-shadow: 0 0 0 2px $accent_green;
    }

    .cell {
      width: calc(25% - 10px);
      padding: 4px;
      margin-left: 9px;
      overflow: hidden;
    }

    .edit {
      width: 20px;
      margin: 6px;
    }
  }
}