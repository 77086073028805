.field {
    padding: 6px 0;
}

.fieldError {
    margin: 0;
    padding: 5px 0 10px;
    color: #FF245A;
}

.labelField {
    margin-right: 5px;
}

.inputField {
    margin: -10px 0;
    display: flex;
    align-items: center;
}

.checkboxField {
    margin: 0 0 -10px;
}

.selectTabField {
    margin-top: -24px;
}
