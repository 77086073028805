@import "../../theme/colors";

.inputSelect {
  padding-bottom: 6px;

  .selectElement {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 15px;

    position: relative;
    margin-bottom: 10px;
    cursor: pointer;

    /* Create a custom checkbox */
    .checkmark {
      margin-right: 6px;
      height: 14px;
      width: 14px;
      background-color: $gray_lighter;

      &.active {
        background-color: $primary;
      }
    }

    .radio {
      border-radius: 50%;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;

      left: 5px;
      top:  2px;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(40deg);
      -ms-transform: rotate(40deg);
      transform: rotate(40deg);
    }

    .active.checkmark:after {
      display: block !important;
    }

    .otherInput {
      margin-left: 6px;
      border: solid 1px $gray;

      padding-left: 4px;
      height: 20px;
      max-width: 100px;
      overflow-x: auto;
    }
  }

}