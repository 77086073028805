.container {
  width: 300px;
  display: flex;

  .icon {
    margin-right: 15px;
  }

  .title {
    margin-bottom: 10px;
  }
}
