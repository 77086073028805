@import '../../theme/colors';

@font-face {
  font-family: 'GothamRounded';
  src: url('../../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.JavascriptWrapper {

  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-width: 200px;
  width: 100%;

  border: solid 1px $gray;
  border-radius: 2px;

  .codeWrapper {
    width: 100%;
    padding: 5px 8px;
    background-color: $gray_lightest;
    overflow-x: scroll;

    .title {
      font-family: 'GothamRounded', sans-serif !important;
    }
  }

  .copyWrapper {
    padding: 5px;
    margin-left: auto;
    margin-right: 5px
  }
}
