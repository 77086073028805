.timeLine {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeLine_visualization {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.timeLine_circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid transparent;
}

.timeLine_line {
    height: 2px;
    width: 10px;
    opacity: 0.5;
}
