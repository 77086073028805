@import '../../theme/colors';


.priceModal {

  border-radius: 4px;
  padding: 24px;
  background-color: $white;

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 450ms ease-out;

  .row {
    width: 100%;
  }

  .xtitle {
    font-size: 3rem !important;
    line-height: 3.2rem;
  }
}