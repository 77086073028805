@import "../../theme/colors";


.priceSelector {

  margin: 12px;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  box-shadow: 0 0 10px 2px $gray_light;
  max-width: 600px;
  width: 100%;

  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
    font-size: 0.95rem;
    margin: 24px 0;
  }

  .table {
    margin: 20px;

    .header {
      border-bottom: 1px solid $gray_light;

      .head {
        text-transform: uppercase;
        color: $gray;
      }
    }

    .column {


      &.wider {
        width: 40%
      }

      &.narrow {
        width: 20%
      }
    }

    .row {
      padding: 12px;

      display: flex;
      flex-direction: row;
      align-content: center;
      width: 100%;

      &.active {
        background-color: $gray_light;
      }

      transition: background-color 0.2s linear;

      &:hover {
        background-color: $gray_lighter;
      }
    }
  }

  .priceExplainer {
    background-color: $background;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 24px;

    .features {
      margin-top: 12px;

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      flex-direction: row;

      @media screen and (max-width: 400px) {
        flex-direction: column;
      }

      .feature {
        margin: 0 24px;
        max-width: 200px;
        width: 100%;
        font-size: 0.8rem;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
      }
    }

  }
}