// arrow is half of square, so size is basically half of that square diagonal
// INFO: if changing arrow_size, minSize in js should be changed also.
$arrow_size: 10px;
// side is side of a square (1.41 = Math.sqrt(2), this formula is from Pythagoras theorem, when two sides are same)
$arrow_side: $arrow_size * 1.41;

.wrapper {
  position: relative;
  width: fit-content;
  display: inline-block;
}

// popup container

.popup {
  transform: scale(0);
  position: absolute;
  transition: all .1s linear;

  // highest possible level
  z-index: 1500;
}

.popup_top {
  top: -$arrow_size;
}

.popup_bottom {
  bottom: -$arrow_size;
}

.popup_left {
  left: -$arrow_size;
}

.popup_right {
  right: -$arrow_size;
}

// inner

.popup_inner {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 12px;
}

// arrow

.popup_arrow {
  width: $arrow_side;
  height: $arrow_side;
  background: linear-gradient(45deg, white 55%, transparent 50%);
  position: absolute;
  margin: -$arrow_side / 2;
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-color: transparent transparent #ddd #ddd;
}

.popup_arrow_top {
  bottom: 0;
  transform: rotate(-45deg);
}

.popup_arrow_bottom {
  top: 0;
  transform: rotate(135deg);
}

.popup_arrow_left {
  right: 0;
  transform: rotate(-135deg);
}

.popup_arrow_right {
  left: 0;
  transform: rotate(45deg);
}

// close icon

.popup_close {
  position: absolute;
  top: 3px;
  right: 3px;
}
