@import '../../theme/colors';

.modalLayout {
  background-color: $secondary;

  .modalContent {

    min-height: 100vh;
    min-width: 98vw;
    display: flex;

    .modal {
      position: relative;

      background-color: $background;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      z-index: 3;
      transition: all 300ms linear;

      margin: auto;
      padding: 42px 32px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      overflow-y: auto;

      @media screen and (max-width: 550px) {
        padding: 18px;
      }

      .close {
        position: absolute;
        top: 12px;
        right: 12px;
      }

      .title {
        margin: 12px 0;
      }
    }
  }
}