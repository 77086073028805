@import '../../theme/colors';

.sideModal {


  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .BackLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, .6);
    animation: 0.3s ease-out 0s 1 fadein;

    cursor: pointer;
  }

  .sideModalWrapper {

    animation: 0.3s ease-out 0s 1 slideFromSide;

    position: absolute;

    max-width: 1400px;
    min-width: 250px;
    width: 100%;

    height: 100%;

    background-color: $background;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 1700px) {
      max-width: calc(100% - 150px);
    }

    @media screen and (max-width: 1200px) {
      max-width: calc(100% - 50px);
    }

    .navBar {
      // padding-top: 150px;
      position: absolute;
      width: 100%;
      z-index: 900;
      top: 0;

      height: 60px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding: 105px 38px 30px 24px;

      background: $white;

      box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

      transition: background-color 0.3s ease-in-out;

      .title {
        display: flex;
        align-items: center;
      }

      .buttons {
        display: flex;
        flex-direction: row;
      }

      &.active {
        background-color: $secondary_light;
      }
    }


    .body {
      height: 100%;
      position: relative;
      overflow-y: scroll;
      padding: 130px 12px 24px 24px;
    }
  }


  @keyframes slideFromSide {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
