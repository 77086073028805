@import '../../../theme/colors';


.section {
    overflow: auto;
    padding: 40px 20px;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;

    .content {
        max-width: 380px;
        width: 100%;
        padding-bottom: 24px;

        .InfoContainer {
            border: 1px solid $primary_light;
            background-color: $primary_background;
            padding: 12px;
            border-radius: 6px;
            margin-bottom: 18px;
        }
    }

}