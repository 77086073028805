@import '../../theme/colors';

.subtitle {
  font-weight: lighter;
  color:$black2;
}

.cardContainer {

  max-width: 950px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

  padding-top: 8px;

  background: $white;
  margin: 24px 0;

  z-index: 5;

  .hero {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 12px 24px;

    .buttonsRight {
      display: flex;
      flex-direction: row;
      .collapsable {
        margin-left: 12px;
        padding: 6px;
        transition: transform 0.8s;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    .title h6 {
      font-weight: lighter;
      color:$black71;
    }


  }

  .paddedContent {
    padding: 6px 24px;
  }

  .NoPaddedContent {
    padding-bottom: 6px;
  }

  .collapsable {

    transition:
            max-height 0.6s ease-in-out,
            opacity 0.4s ease-in-out;

    &.closed {
      max-height: 0;
      opacity: 0;
    }

    &.open {
      max-height: 500px;
      overflow-y: auto;
      opacity: 1;
    }
  }

  .footer {
    background-color: $secondary_light;
    width: 100%;
    height: 60px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    overflow: hidden;
    transition:
            padding-top 0.6s ease-in-out,
            height 0.6s ease-in-out,
            opacity 1s ease-in-out;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;


    &.closed {
      height: 0;
      opacity: 0;
      padding: 0 24px;
    }

    &.open {
      height: 60px;
      opacity: 1;
      padding: 9px 24px;
    }
  }
}
