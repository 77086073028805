@import '../../theme/colors';

.Tag {

  display: inline-block;
  padding: 2px 9px;
  margin: 4px 0;
  border-radius: 12px;

  &.light {
    background: $gray_lightest
  }

  &.default {
    background: $gray_lighter;
  }

  &.update {
    background: $accent_blue;
  }

  &.active {
    background: $accent_blue;
  }

  &.new {
    background: $accent_blue;
    font-weight: 700;
  }

  &.alert {
    background: $alert;
    font-weight: 700;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }

}