@import '../../theme/colors';

.livePage {

  position: relative;
  width: 100%;

  .section {

    .content {
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .liveTop {

    padding: 94px 42px 50px 42px;


    background-color: $blue153;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .info {
      background-color: $transparent;
      padding: 18px;
      position: relative;
      border-radius: 8px;

      .info {
        position: absolute;
        top: 19px;
        right: 17px;
      }

      .title {
        border-bottom: 1px solid $darkgray_light;
        padding-bottom: 8px;
      }

      .row {
        display: flex;
        flex-direction: row;

        .counter {
          padding: 4px;

          h1 {
            font-size: 3rem;
            padding: 12px 0;
          }
        }
      }
    }

  }

  .badge {
    position: relative;
    padding: 0 42px;
    margin-top: -18px !important;

    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    height: 40px;

    .badgeButton {
      border-radius: 25px;
      padding: 9px 12px;

      cursor: pointer;

      box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 140px;
      

      &:hover {
        background-color: $blue110;
      }
      p{
        color: white !important;
      }
    }

    .progressbar {
      height: 4px;
      animation: 4s ease-in 0s infinite progress;
    }

    @keyframes progress {
      0% {
        width: 0;
        opacity: 0;
      }
      80% {
        width: calc(100% - 140px);
        opacity: 1;
      }
      100% {
        width: calc(100% - 140px);
        opacity: 0;
      }
    }
  }

  .table {

    padding: 0 40px 40px 40px;
    width: 100%;

    .no-events {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }

    .tableWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .collapsableEvent {
        margin: 0 6px;
        padding: 4px 24px;
        background-color: $background;
        width: 100%;

        box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: flex-start;

        animation: 0.6s ease-out 0s 1 openSlide;

        .rotate {
          transform: rotate(-90deg);
          align-self: center;
        }

        .bodyData {
          margin: 6px;
          padding: 24px;
          background-color: $white;
          width: 100%;

          .titleAligned {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      @keyframes openSlide {
        0% {
          opacity: 0;
        }
        100% {
          max-height: 100%;
          opacity: 1;
        }
      }
    }
  }
}