@import "../../theme/colors";

.resizeWrapper {
  display: flex;
  flex-direction: row;

  .resize {
    width: 32px;
    min-height: 100%;
    display: flex;
    cursor: col-resize;

    .borders {
      border-left: 2px solid $gray;
      border-right: 2px solid $gray;

      width: 6px;
      height: 12px;
      margin: auto;
    }
  }
}