.sectionDialog_cover {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f8f9;
}

.sectionDialog {
  max-width: 450px;
  width: 100%;
  padding: 0 25px 25px 25px;
  background: white;
  box-shadow: 0 0 5px 0 #ccc;
}
