@import '../../theme/colors';


.InstallationWrapper {
  max-width: 1150px;

  .bodyModal {
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);

    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 450ms ease-out;

    .bodyContent {
      padding: 62px 42px 42px 42px;

      .bodyTitle {
        margin: 42px 0 12px 0;
        padding: 4px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid $gray_light;

        .title {
          font-size: 0.9rem;
          line-height: 0.9rem;
          font-weight: 400;
        }

        .buttonCheck {
          background: $white;
          border: 1px solid $primary;
          font-weight: 700;
          border-radius: 2px;
          padding: 3px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .instructions {

      justify-content: space-between;
      align-items: center;

      padding: 18px 42px;

      background-color: $blue153;
      width: 100%;
    }
  }

}