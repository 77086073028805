.mobileView {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mobileView_top {
    flex: 1;
    max-height: 200px;
    min-height: 120px;
    background: #454468;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mobileView_content {
    padding: 30px 40px;
    text-align: center;
}