@import '../../theme/colors';

@font-face {
  font-family: 'GothamRounded';
  src: url('../../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.sideBarFont {
  color: $black120;
  font-family: 'GothamRounded', sans-serif !important;
  font-size: 12px;
}

.sideBarIcon svg path{
  color: $blue153;
  background-color: $blue153;
  fill: $blue153;
}

.menuContainer {

  min-height: 0;
  padding: 0 0 0 24px;
  width: 200px;

  // max height minus trial and menu
  max-height: calc(100vh - 160px);

  @media screen and (max-height: 530px) {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border: solid 1px $transparent;

      &:hover {
        background-color: $darkgray_light;
      }
    }
  }

  .section {

    padding-top: 32px;

    .sectionTitle {
      margin-bottom: 10px;
      font-size:14px;
    }

    .page {
      margin: -2px 0;

      .titleWrapper {
        padding: 6px 6px 6px 10px;

        display: flex;
        flex-direction: row;
        align-items: center;

        border-bottom-left-radius: 24px;
        border-top-left-radius: 24px;

        .pageTitle {
          margin-left: 6px;
        }

        &.active {
          background-color: $secondary;
        }

        &.active_inverse {
          background-color: $white;
        }
      }

      .subpageWrapper {
        max-height: 0;
        overflow: hidden;
        transition:
                max-height 0.4s ease-in-out,
                opacity 0.8s ease-in-out;

        &.closed {
          max-height: 0 !important;
          opacity: 0;
        }

        &.open {
          opacity: 1;
        }

        .subpage {
          margin-left: 32px;
          padding: 3px 6px 3px 12px;

          border-bottom-left-radius: 24px;
          border-top-left-radius: 24px;

          &.active {
            background-color: $secondary;
          }
        }
      }
    }
  }

}