@import '../../theme/colors';

.alertContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1049;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: fixed;

      background-color: rgba(0, 0, 0, .6);

      animation: 0.3s ease-out 0s 1 fadein;
    }

    .modal {
        padding: 24px 42px;
        z-index: 1050;
        max-width: 280px;
        width: 100%;
        animation: 0.3s ease-out 0s 1 slideInFromBottom;

        background-color: $background;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        .buttonContainer {
          width: 80%;
          margin: 0 auto;
          padding-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
    }

    @keyframes slideInFromBottom {
      0% {
        transform: translateY(40%);
        opacity: 0;
      }
      50% {
        transform: translateY(40%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
}