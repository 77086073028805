@import '../../theme/colors';


.not_found {
  background-color: #fff;

  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .content {
    margin-top: 15vh
  }
}