@import '../../theme/colors';

.pageLayout {
  position: relative;

  .rulesHero {
    height: 170px;
    width: 100%;
    // background-color: $white;
    background-color: $black5;

    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 64px 42px;

    .publishWrapper {
      padding: 12px;
      background-color: $background;
      width: 300px;
      transition: all 200ms ease-out;
      border-radius: 8px;

      &.active {
        color: $white;
        background-color: $blue153;
        margin-top: 3vh;

        p {
          color: $white;
        }
      }

      .title {
        border-bottom: 1px solid $gray_light;
        padding-bottom: 8px;
        margin-bottom: 8px;
      }
    }

  }

  .rulesBody {
    margin-top: -60px;
    padding: 64px 42px;
  }

  .publishModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: fixed;

      background-color: rgba(0, 0, 0, .6);
    }

    .publishModal {
      z-index: 1050;
      max-width: 280px;
      width: 100%;

      background-color: $white;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      padding: 42px 24px;
      min-width: 350px;

      .buttons {
        margin: 0 auto 12px;
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}