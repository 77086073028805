@import '../../theme/colors';

.dataFieldPreview {
  height: 100%;
  width: 100%;

  .invalid {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 0 6px 4px;

    .msg {
      margin-left: 4px;
    }
  }
}