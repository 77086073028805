@import "../../theme/colors";

.tabsWrapper {

  display: flex;
  flex-direction: row;
  margin-top: 4px;
  height: 30px;

  .tab {
    margin-left: 6px;
    padding: 3px 6px;

    width: 100px;
    background-color: $gray_light;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    transition: all .3s ease;

    &.active {
      margin-bottom: -1px;
      background-color: $white;
      border: solid 1px $gray;
      border-bottom-color: $white;
    }
  }
}