@import "../../theme/colors";

.Filter {

  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  position: relative;

  .filterWrapper {
    position: relative;

    .filterButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 9px 1px;

      margin: 0 6px;

      background: $transparent;
    }

    .filterList {
      position: absolute;
      z-index: 100;

      background: $white;

      top: 37px;
      left: 1px;

      min-width: calc(100% - 12px);

      margin: 0 5px;

      overflow-y: hidden;
      overflow-x: auto;

      &.closed {
        max-height: 0 !important;
        opacity: 0;
        box-shadow: none;
        border: none;

        transition: max-height 0.4s ease-in-out,
                    opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.1s ease-in-out;
      }

      &.open {
        opacity: 1;
        box-shadow: 0 2px 2px 0 rgba(69, 68, 104, 0.14), 0 3px 1px -2px rgba(69, 68, 104, 0.14), 0 1px 5px 0 rgba(69, 68, 104, 0.14);
        border: 1px solid $gray_light;
        transition: max-height 0.3s ease-in-out,
                    opacity 0.6s ease-in-out, box-shadow 0.3s ease-in-out, border 0.1s ease-in-out;
      }

      .filterListElement {
        padding: 6px;
        display: flex;
      }

      .filterOptions {
        min-width: 200px;
        padding: 12px 12px 0 12px;
        margin-bottom: 4px;
        border-bottom: 1px solid $gray_light;
      }

      .filterButtons {
        padding: 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

}