@import "../../theme/colors";

.jsonWrapper {

  width:100%;
  overflow: auto;

  .Jsonrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .keyCell {
      width: 150px;
      display: flex;

      .keyTag {
        max-width: 170px;
        overflow: auto;
        -ms-overflow-style: none;  // IE 10+
        scrollbar-width: none;  // Firefox
        &::-webkit-scrollbar {
          display: none;  // Safari and Chrome
        }
      }
    }

    .toggleCell {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 130px;
      min-width: 120px;

      padding: 3px 8px;
      border-radius: 4px;
      border: solid 1px $gray;

      &.noBorder {
        justify-content: flex-start;
        border: 0;
        p {
          margin-left: 6px;
        }
      }
      .arrow {
        transition: transform 0.4s ease-in-out, opacity 0.6s ease-in-out;
        opacity: 1;

        &.noOpac {
          opacity: 0;
        }

        &.leftRotate {
          transform: rotate(90deg);
        }

        &.downRotate {
          transform: rotate(180deg);
        }

        &.rightRotate {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .nested {
    padding-left: 42px;

    max-height: 0;
    overflow: hidden;
    transition:
            max-height 0.4s ease-in-out,
            opacity 0.8s ease-in-out;
  }

  .Jsonclosed {
    max-height: 0 !important;
    opacity: 0;
  }

  .Jsonopen {
    opacity: 1;
    max-height: 1000px;
    overflow-y: auto;
  }
}