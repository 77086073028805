@import "../../theme/colors";

.slider_wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	height: 36px;
	user-select: none;
	margin: 0 auto;
}

.slider {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	height: 22px;
	cursor: pointer;
}

.slider_rail {
	position: absolute;
	width: 100%;
	height: 9px;
	border-radius: 9px;
	background-color: $gray;
}

.slider_track {
	position: absolute;
	left: 0;
	height: 9px;
	border-radius: 9px;
}

.slider_thumb {
	position: absolute;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	transform: translateX(-11px);
	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1px 0 0 1px;

	box-shadow: 0 0 8px 3px $primary_light;
	transition: box-shadow 0.2s ease-in-out;

	&:active, &:hover {
		box-shadow: 0 0 4px 2px $primary_light;
	}
}

.slider_label {
	color: #000;
	margin-left: 15px;
    width: 50px;
}
