@import '../../theme/colors';


.wiki {
  // Style overrides

  h3 {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 8px;
    text-transform: none
  }

  h5 {
    margin-top: 24px;
  }

  h6 {
    margin-bottom: 24px;
  }

  p, pre {
    padding-bottom: 9px;
  }

  blockquote {
    margin: 12px;
    padding: 9px 0 0 9px;
    border-left: solid 3px $primary;
    background-color: #caecff;
  }

  code {
    background-color: $darkgray;
    color: $white;
    border-radius: 2px;
    padding: 4px 6px;
  }

  img {
    max-width: 600px;
  }
}