.getStarted_big {
    height: 100vh;
    display: flex;
}

.getStarted_small {
    @extend .getStarted_big;
    flex-direction: column;
}

.getStarted_info_big {
    width: 450px;
    min-width: 450px;
    display: flex;
}

.getStarted_info_small {
    height: 60px;
}

.getStarted_section {
    flex: 1;
    display: flex;
}

.mobileHeader {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
}
