@import '../../theme/colors';

.Modal {
  position: relative;

  .modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1540;

    .backLayer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 1530;

      background-color: rgba(0, 0, 0, .6);
      animation: 0.3s ease-out 0s 1 fadein;

      .mouseclick {
        cursor: pointer;
      }
    }

    .modalContent {
      position: relative;


      .close {
        position: absolute;
        top: 12px;
        right: 12px;
      }

      z-index: 1550;
      width: 100%;

      background-color: $white;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      padding: 42px 24px;

      //max-width: 280px;
      min-width: 350px;

      .interior {

        max-height: 80vh;
        overflow-y: auto;
      }

    }
  }
}