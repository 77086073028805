@import '../../theme/colors';

.installationPage {
  position: relative;
  background-color: $gray_lighter;

  width: 100%;

  padding: 62px 42px;

  display: flex;
  flex-direction: row;
  justify-content: center;
}
