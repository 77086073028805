.tagOrInput {

  &.inactive {
    cursor: pointer !important;
  }

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;

    .breadcrumb {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}