.formInline {
    display: flex;

    > * {
        flex: 1;

        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}
