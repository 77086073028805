@import '../../theme/colors';

@font-face {
  font-family: 'GothamRounded';
  src: url('../../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.signingPage {
  padding: 12px 32px;

  .signed {
    position: relative;

    .signature {
      position: absolute;
      height: 130px;
      margin-top: -124px;
      margin-left: 95px;
    }

    .name {
      margin-left: 6px;
      font-family: 'GothamRounded', sans-serif;
    }
  }

  table.report-container {
    page-break-after: always;
  }

  thead.report-header {
    display: table-header-group;
  }

  tfoot.report-footer {
    display: table-footer-group;
  }

  initials:last-child{
    display: none;
  }

  h3 {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  h4, h5 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  p {
    padding-bottom: 10px;
  }

  .initials {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .initial {
      margin: 2px;
      border: solid 1px $gray;
      padding: 3px 3px 24px 3px;
    }

    .signature {
      top: 13px;
      position: absolute;
      height: 35px;
      right: 99px;
    }
  }

}