@import "../../theme/colors";

.inputElement {

  position: relative;
  width: 100%;

  .Input {
    width: 100%;
    position: relative;
    margin: 10px 0;
    padding: 0 5px;
    background-color: $white;
    border-radius: 2px;

    transition: all 150ms ease-out;
    display: flex;

    align-items: flex-start;
    flex-direction: column;

    border: solid 1px $gray;

    &.focus {
      border: 1px solid $primary !important;
    }

    &.invalid {
      border: 1px solid $alert !important;
    }

    .label {
      background: $white;
      z-index: 0;
      position: absolute;
      top: 9px;
      left: 5px;
      color: $darkgray_light;

      font-size: 0.72rem;
      font-weight: 400;

      transition: all 200ms ease-out;

      &.focus {
        transform: translate(-3px, -16px);
        border-radius: 3px;
        padding: 0 3px;
        font-size: 0.6rem;
        color: $primary_light;
        z-index: 10;
      }

      &.invalid {
        color: $alert;
      }
    }

    .inputWrapper {
      position: relative;
      width: 100%;

      .input {
        color: #444444;
        font-size: 0.72rem;
        font-weight: 400;
      }

      .validator {
        position: absolute;
        right: 0;
        top: 6px;
      }
    }
  }

  .choices {
    position: absolute;
    z-index: 100;
    background-color: $background;
    box-shadow: 0 2px 2px 0 rgba(69, 68, 104, 0.14), 0 3px 1px -2px rgba(69, 68, 104, 0.14), 0 1px 5px 0 rgba(69, 68, 104, 0.14);

    top: 31px;
    margin: 0 5px;
    min-width: calc(100% - 10px);

    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    overflow-y: hidden;
    overflow-x: auto;

    &.scrollable {
      overflow-y: scroll;
    }

    &.closed {
      max-height: 0 !important;
      opacity: 0;

      transition: max-height 0.4s ease-in-out,
      opacity 0.3s ease-in-out;
    }

    &.open {
      opacity: 1;

      transition: max-height 0.3s ease-in-out,
      opacity 0.6s ease-in-out;

    }

    .choice {
      padding: 4px;
      display: flex;

      &.highlight {
        background-color: $secondary_lighter;

        span {
          color: $white !important;
        }
      }
    }
  }
}