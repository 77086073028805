@import "../../theme/colors";

@font-face {
  font-family: 'GothamRounded';
  src: url('../../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.jsonRaw {
  position: relative;

  .jsonInput {
    font-family: 'GothamRounded', sans-serif !important;
    border: none;
    width: 95%;
    min-height: 300px;
    margin: 30px 9px;
  }

  .validator {
    position: absolute;
    left: 8px;
    top: 5px;

    display: flex;
  }
}