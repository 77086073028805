@import '../../theme/colors';

@font-face {
  font-family: 'GothamRounded';
  src: url('../../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


.TableWrapper {

  margin: 12px 0;
  width: 100%;

  .bottomBorder {
    box-shadow: 0 1px 0 0 $gray_light;
  }

  table {
    font-family: 'GothamRounded', sans-serif !important;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    table-layout: inherit;

    &.narrow {
      td, th {
        padding: 0 2px;
      }
    }

    &.wide {
      td, th {
        padding: 0 12px;
      }
    }

    th {
      height: 35px;
    }

    td:first-child, th:first-child {
      padding-left: 24px;
    }

    td, th {
      text-align: left;
      box-shadow: 0 1px 0 0 $gray_light;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
}