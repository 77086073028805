@import '../../theme/colors';


.inputCard {

  .inputContainer {
    margin: 0 24px;


      .inputElement {
        display: flex;
        flex-direction: row;
        justify-items: flex-start;
        align-items: center;
    }

    .formatIndicator {
      margin-top: -3px;
      margin-bottom: 9px;
      color: $alert
    }
  }
}