// Brand colors
$primary: #187BC0;
$primary_light: #5598F2;
$primary_lighter: #9078f7;
$primary_background: rgba(85, 152, 242, 0.1);

$secondary: #232142;
$secondary_light: #3E4269;
$secondary_lighter: #454468;

$gray: #C4CED8;
$gray_light: #E4E9EE;
$gray_lighter: #EFF2F5;
$gray_lightest: #F8F9FA;


$darkgray: #444444;
$darkgray_light: #7f7f7f;
$darkgray_lighter: #8e8e8e;

$white: #fff;
$black: #0A0927;
$transparent: rgba(255, 255, 255, 0.1);

$accent_green: #A2FF73;
$accent_green_dark: #7cda2c;
$accent_blue: #55F1F2;
$accent_pink: #F373FF;
$accent_black: black;

$alert: #FF245A;

$blue110 :#0074E8;
$blue40: #99CCFF;
$blue153 :#084888;
$black2: #FBFBFC;
$black5: #F5F5F6;
$black20: #D6D9DD;
$black71: #6F7786;
$black120: #212E39;


// Functional
$background: $gray_lighter;
$section: $white;
