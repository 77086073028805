@import '../../theme/colors';

.DataFieldObject {
  width: 100%;
  margin: 24px 0;

  .codeModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: fixed;

      background-color: rgba(0, 0, 0, .6);
    }

    .codeModal {
      z-index: 1050;
      width: 100%;
      max-width: 550px;

      background-color: $white;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      padding: 42px 24px;
      min-width: 350px;

      .buttons {
        margin: 0 auto 12px;
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid $gray_light;

    padding: 0 20px;

    .cell {
      padding: 0 4px;
      width: 100%;

      .addNew {
        margin: 10px 0;
        width: 80px;
        height: 30px;
        border-radius: 2px;
        border: 1px solid $gray;
        padding: 2px 8px;
        transition: all 150ms ease-out;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border-color: $primary
        }
      }
    }

    .header {
      padding: 0 6px;
      margin: 10px 0;
      width: 100%;
    }

    .propertyTypeHeader {
        margin-left: 230px;
    }
  }
}