@import '../../theme/colors';

.gdprModalWrapper {

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .gdprModal {
    position: relative;

    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
    background-color: white;
    width: 70%;

    display: flex;
    flex-direction: column;

    .modalOnboardContent {
      z-index: 80;
      display: flex;
      flex-direction: row;
      min-height: 400px;

      .bodyModal {
        border-radius: 4px;
        background-color: $white;

        max-height: calc(95vh - 70px);
        overflow: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        transition: all 450ms ease-out;

        .bodyContent {
          padding: 42px 24px 24px 24px;

          .terms {
            margin-top: 24px;
          }

          .consent {
            font-size: 0.85rem;
            line-height: 0.9rem;
            font-weight: 700;
          }
        }

      }
    }
  }
}