@import "../../theme/colors";

.selectTabsWrapper {

  display: flex;
  flex-direction: row;
  margin-top: 24px;
  height: 30px;

  .tab:not(:last-child) {
    margin-right: 12px;
  }

  .tab {
    padding: 3px 12px;

    width: 100%;

    background-color: $white;
    border: solid 1px $gray_light;

    border-radius: 4px;

    transition: all .3s ease;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: $gray_light;
      border: solid 1px $gray_light;
    }

    .checkmark {
      position: relative;
      width: 15px;
      height: 15px;
      margin-right: 7px;
      border-radius: 50%;

      background-color: $white;
      border: solid 1px $gray_light;

      &.active {
        background-color: $primary;
        border: solid 1px $primary;
      }
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;

      left: 5px;
      top:  2px;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(40deg);
      -ms-transform: rotate(40deg);
      transform: rotate(40deg);
      display: block !important;
    }
  }
}