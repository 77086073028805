@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900');
@import './colors.scss';

@font-face {
  font-family: 'GothamRounded';
  src: url('../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}

.not-allowed {
  cursor: not-allowed !important;
}

.delete {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.right {
  text-align: right;
  margin-left: auto;
}

.center {
  text-align: center;
}

.verticalpadding {
  padding: 12px 0;
}

.spacer {
  padding-bottom: 12px;
}

.bigSpacer {
  padding: 18px 0;
}

.white {
  color: $white;
}

.darkgray {
  color: $darkgray
}

.accent {
  color: $black71
}

.alert {
  color: $alert
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.bold, b {
  font-weight: 700 !important;
}

.black {
  font-weight: 900 !important;
}

.small, .code, code {
  font-size: 0.68rem;
  line-height: 0.85rem;
}

textarea, p, ul, li, h5, script, .normal {
  font-family: 'GothamRounded', sans-serif;
  color: $darkgray;
  font-size: 0.72rem;
  line-height: 1rem;
  margin: 4px 0;
}

li {
  margin-left: 18px;
}

ul {
  margin: 6px 16px;
}

a, .link {
  font-family: 'GothamRounded', sans-serif;
  text-decoration: none;
  cursor: pointer;
  color: $primary;

  &:hover {
    text-decoration: underline;
  }
}


h6 {
  color: $primary;
  font-size: 0.85rem;
  line-height: 1.05rem;
  font-weight: 700;
}

h5 {
  font-size: 0.75rem;
  line-height: 0.9rem;
  font-weight: 700;

  color: $darkgray_light;
  // text-transform: uppercase;
}

h4, .large {
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;

  // text-transform: uppercase
}

h3 {
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;


  @media screen and (max-width: 750px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

h1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 600;

  @media screen and (max-width: 750px) {
    font-size: 1.7rem;
    line-height: 2rem;
  }
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'GothamRounded', sans-serif;
  padding: 0;
  margin: 0;
}

.code {
  font-family: 'GothamRounded', serif;
  background: $gray_lighter;
  border: 1px solid $gray_light;
  color: $black;
  border-radius: 2px;
  padding: 1px 4px;
  margin: 0 4px;
}

.centerWrapper {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  max-width: 1200px;

}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
  filter: alpha(opacity=0); /* For IE8 and earlier */
  opacity: 0;
  height: 0;
}

.primary {
  color: $primary
}

// Animations

.slideInFromTop {
  animation: 0.6s ease-out 0s 1 slideInFromTop;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-40%);
    opacity: 0;
  }
  50% {
    transform: translateY(-40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInFromBottom {
  animation: 0.6s ease-out 0s 1 slideInFromBottom;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(40%);
    opacity: 0;
  }
  50% {
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInFromLeft {
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-40%);
    opacity: 0;
  }
  50% {
    transform: translateX(-40%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInFromRight {
  animation: 1.5s ease-out 0s 1 slideInFromRight;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(40%);
    opacity: 0;
  }
  50% {
    transform: translateX(40%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fadein {
  animation: fadein 1s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinin {
  animation: spinin 1s;
}

@keyframes spinin {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(720deg);
  }
}

.wiggle {
  animation: wiggle 3s infinite;
}

@keyframes wiggle {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  71% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  74% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  77% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  80% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  83% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  86% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  89% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  91% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  94% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  97% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.bounce {
  -moz-animation: bounce 1.3s infinite;
  -webkit-animation: bounce 1.3s infinite;
  animation: bounce 1.3s infinite;
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -moz-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

.blink {
  -webkit-animation: blink 1.5s infinite alternate;
  -moz-animation: blink 1.5s infinite alternate;
  -o-animation: blink 1.5s infinite alternate;
  animation: blink 1.5s infinite alternate;
}

@-webkit-keyframes blink {
  from {
    background-color: #d1d1d8;
  }
  to {
    background-color: #f4f4f6;
  }
}

@-moz-keyframes blink {
  from {
    background-color: #d1d1d8;
  }
  to {
    background-color: #f4f4f6;
  }
}

@-o-keyframes blink {
  from {
    background-color: #d1d1d8;
  }
  to {
    background-color: #f4f4f6;
  }
}

@keyframes blink {
  from {
    background-color: #d1d1d8;
  }
  to {
    background-color: #f4f4f6;
  }
}

// RESET


*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body, html {

  font-family: 'GothamRounded', sans-serif;
  font-weight: 400;
  background-color: $white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 934px);
  box-sizing: border-box;
}

@page {
  size: 21cm 29.7cm;
}

body.disableScroll {
  overflow: hidden;
}

button:active, button:focus, button.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}


input {
  font-family: 'GothamRounded', sans-serif;

  text-align: left;
  z-index: 10;
  border: none;
  background-color: transparent;



  box-shadow: none;
  height: 30px;

  width: 100%;

  transition: all 200ms ease-out;

  &::-webkit-input-placeholder {
    color: $darkgray_light;;
  }

  ::placeholder {
    color: $darkgray_light;;
  }

  &:focus {
    outline: none;
  }
}

textarea {
  resize: none;
}

textarea:focus, input:focus {
  outline: 0;
}

*:focus {
  outline: 0;
}


// Scrollbar

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $gray;
  border-radius: 3px;
  transition: background-color 1s linear;
  border: solid 1px $white;

  &:hover {
    background-color: $darkgray;
  }
}


.testasdsadsaddsa {
  color: black;
}

.headerCustomDimensions{
    padding-left:25px;
}
