@import "../../theme/colors";

@font-face {
  font-family: 'GothamRounded';
  src: url('../../../public/fonts/gotham/GothamRnd-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.Button {
  width: 100%;
  margin: 0;

  border-radius: 8px;

  button {

    border-radius: 25px;
    // background-color: $blue110;

    font-family: 'GothamRounded', sans-serif !important;
    font-weight: 500;

    border: none;
    width: 100%;

    flex: 1 1 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    .buttonWrapper {
      position: relative;
      width: 100%;

      .iconLeft {
        position: absolute;
        left: 9px;
        top: 2px;
      }

      .iconRight {
        position: absolute;
        right: 9px;
        top: 2px;
      }
    }

    &.border {
      border: 1px solid $primary;

    }

  }

  .active {
    cursor: pointer;
    // background-color: $blue110;

    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70); /* For IE8 and earlier */
    }
  }

  .disabled {
    cursor: default;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }

  .loading {
    cursor: progress;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
  }

  button:active, button:focus, button.active {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

}